import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { makeStyles } from '@material-ui/core/styles';
import {
   Box,
   Typography,
   List,
   ListSubheader,
   ListItem,
   ListItemText,
   Paper
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
   paper: {
      padding: theme.spacing(4)
   },
   root: {
      backgroundColor: theme.palette.background.paper,
      position: 'relative',
      overflow: 'auto',
      maxHeight: 'calc(100vh - 20rem)'
   },
   listSection: {
      backgroundColor: 'inherit'
   },
   ul: {
      backgroundColor: 'inherit',
      padding: 0
   }
}));

const adjectivesList = {
   A: [
      'Ambiguo',
      'Abbaggliato',
      'Abbiente',
      'Abietto',
      'Abituale',
      'Abitudinario',
      'Accanito',
      'Accecante',
      'Accidioso',
      'Accomodante',
      'Acido',
      'Acuminato',
      'Adattabile',
      'Adorabile',
      'Affabile',
      'Affamato',
      'Affidabile',
      'Afrodisiaco',
      'Aggressivo',
      'Agile',
      'Allietante',
      'Allucinato',
      'Ambizioso',
      'Ammirevole',
      'Amorevole',
      'Apatico',
      'Apprezzato',
      'Arguto',
      'Arrampicatore',
      'Artistico',
      'Asociale',
      'Assopito',
      'Assordante',
      'Assorto',
      'Astratto',
      'Astruso',
      'Astuto',
      'Atletico',
      'Attento',
      'Attivo',
      'Attraente',
      'Avventuroso'
   ],
   B: [
      'Beffardo',
      'Bello',
      'Benestante',
      'Benevolo',
      'Birbante',
      'Bisognoso',
      'Bravo',
      'Brillante',
      'Brusco',
      'Buono',
      'Burerbo'
   ],
   C: [
      'Caldo',
      'Caloroso',
      'Carino',
      'Casereccio',
      'Celestiale',
      'Chiassoso',
      'Coccolone',
      'Coerente',
      'Colorato',
      'Combattente',
      'Concreto',
      'Conoscitore',
      'Coordinato',
      'Cordiale',
      'Costante',
      'Costruttivo',
      'Creativo',
      'Criptico',
      'Cristallino',
      'Curioso'
   ],
   D: [
      'Delirante',
      'Delizioso',
      'Determinato',
      'Devoto',
      'Didattico',
      'Difensore',
      'Differente',
      'Diligente',
      'Dinamico',
      'Diretto',
      'Discreto',
      'Disordinato',
      'Dispettoso',
      'Disponibile',
      'Distinto',
      'Divertente',
      'Divino',
      'Docile',
      'Dolce'
   ],
   E: [
      'Eccentrico',
      'Ecologico',
      'Edificante',
      'Educato',
      'Effervescente',
      'Efficace',
      'Efficiente',
      'Elegante',
      'Eloquente',
      'Empatico',
      'Energico',
      'Enorme',
      'Entusiasto',
      'Equilibrato',
      'Equo',
      'Ermetico',
      'Eroico',
      'Eroico',
      'Espressivo',
      'Essenziale',
      'Estimato',
      'Estroverso',
      'Etereo',
      'Evanescente'
   ],
   F: [
      'Facile',
      'Famoso',
      'Fantasioso',
      'Fantastico',
      'Fedele',
      'Felice',
      'Fenomenale',
      'Fertile',
      'Fiero',
      'Flessibile',
      'Focoso',
      'Forte',
      'Frastagliato',
      'Freddo',
      'Frettoloso',
      'Frugale',
      'Fumoso',
      'Furbo'
   ],
   G: [
      'Gagliardo',
      'Gaio',
      'Galante',
      'Generoso',
      'Gentile',
      'Gigante',
      'Giocoso',
      'Gioioso',
      'Giulivo',
      'Goffo',
      'Goloso',
      'Grande',
      'Gratificante',
      'Grazioso',
      'Grezzo',
      'Grintoso',
      'Grosso',
      'Guizzante'
   ],
   I: [
      'Ideologico',
      'Idilliaco',
      'Immacolato',
      'Immortale',
      'Imparziale',
      'Impavido',
      'Impellente',
      'Impetuoso',
      'Imponente',
      'Importante',
      'Incoerente',
      'Incorreggibile',
      'Incorruttibile',
      'Indaffarato',
      'Inderogabile',
      'Indifferente',
      'Indipendente',
      'Indiscutibile',
      'Indomabile',
      'Ineguagliabile',
      'Inerte',
      'Ingenuo',
      'Inimitabile',
      'Insicuro',
      'Insospettabile',
      'Insuperabile',
      'Intelligente',
      'Intraprendente',
      'Intrepido',
      'Introspettivo',
      'Introverso',
      'Iracondo',
      'Irraggiungibile',
      'Istruito'
   ],
   L: [
      'Labile',
      'Labirintico',
      'Laborioso',
      'Laconico',
      'Leale',
      'Legale',
      'Leggendario',
      'Leggiadro',
      'Leggibile',
      'Lento',
      'Lercio',
      'Lesto',
      'Letale',
      'Liberale',
      'Libero',
      'Libertino',
      'Libidinoso',
      'Lieto',
      'Lieve',
      'Ligio',
      'Limpido',
      'Lindo',
      'Lineare',
      'Logorroico',
      'Longevo',
      'Loquace',
      'Losco',
      'Luccicante',
      'Lucente',
      'Lucido',
      'Ludico',
      'Luminoso',
      'Lunatico',
      'Lungimirante',
      'Lurido'
   ],
   M: [
      'Macchiavellico',
      'Maestoso',
      'Magico',
      'Magnifico',
      'Marittimo',
      'Masochista',
      'Matematico',
      'Materico',
      'Mattiniero',
      'Matto',
      'Maturo',
      'Melanconico',
      'Melodico',
      'Meraviglioso',
      'Metaforico',
      'Metodico',
      'Minuzioso',
      'Misterioso',
      'Mistico',
      'Mitico',
      'Modico',
      'Molliccio',
      'Multiforme',
      'Musicale',
      'Mutevole'
   ],
   N: [
      'Nascosto',
      'Naturale',
      'Nebbioso',
      'Nobile',
      'Nomade',
      'Notevole',
      'Notturno',
      'Nuotatore',
      'Nuvoloso'
   ],
   O: ['Onesto', 'Orgoglioso', 'Originale', 'Ozioso'],
   P: [
      'Pacato',
      'Pacifico',
      'Pacioccone',
      'Parsimonioso',
      'Pauroso',
      'Pensoso',
      'Perseverante',
      'Perspicace',
      'Persuasivo',
      'Piccante',
      'Piccolo',
      'Pieno',
      'Pigro',
      'Pittoresco',
      'Poetico',
      'Poliedrico',
      'Potente',
      'Predatore',
      'Premuroso',
      'Previdente',
      'Procace',
      'Prode',
      'Progressista',
      'Prossimo',
      'Protettivo',
      'Prudente',
      'Pulito',
      'Pungente',
      'Puntiglioso',
      'Puro'
   ],
   Q: [
      'Quadrato',
      'Qualificato',
      'Quatto',
      'Querulo',
      'Quieto',
      'Quotato',
      'Quotidiano'
   ],
   R: [
      'Rampante',
      'Raro',
      'Repentino',
      'Resiliente',
      'Resistente',
      'Responsabile',
      'Retto',
      'Ribelle',
      'Ridondante',
      'Riflessivo',
      'Rilassante',
      'Rilassato',
      'Risolutivo',
      'Rispettoso',
      'Risplendente',
      'Robusto',
      'Rocambolesco',
      'Romantico',
      'Rovente',
      'Rozzo',
      'Rustico',
      'Ruvido'
   ],
   S: [
      'Sagace',
      'Saggio',
      'Scalatore',
      'Scandito',
      'Scavatore',
      'Schietto',
      'Schivo',
      'Scostante',
      'Sedentario',
      'Selettivo',
      'Sensibile',
      'Sentenzioso',
      'Silente',
      'Simpatico',
      'Sincero',
      'Socievole',
      'Sognatore',
      'Solare',
      'Solcato',
      'Solitario',
      'Sospettoso',
      'Spassoso',
      'Spontaneo',
      'Stanco',
      'Stilizzato',
      'Storico',
      'Stridulo',
      'Stupendo',
      'Svelto'
   ],
   T: [
      'Tacito',
      'Taciturno',
      'Temibile',
      'Tempestivo',
      'Tenace',
      'Tenero',
      'Testardo',
      'Timido',
      'Timoroso',
      'Titubante',
      'Toccante',
      'Tollerante',
      'Tosto',
      'Tranquillo',
      'Trascinatore',
      'Trasparente',
      'Travolgente',
      'Triste',
      'Turbato'
   ],
   U: [
      'Ubbidiente',
      'Uggioso',
      'Ultras',
      'Umile',
      'Umoristico',
      'Unico',
      'Unito',
      'Universitario',
      'Urtante',
      'Urticante',
      'Usuale',
      'Utile',
      'Utopistico'
   ],
   V: [
      'Valoroso',
      'Variabile',
      'Velata',
      'Veloce',
      'Veritiero',
      'Versatile',
      'Virile',
      'Virtuoso',
      'Visionario',
      'Vispo',
      'Vistoso',
      'Vivace',
      'Vivido',
      'Volatile',
      'Volubile',
      'Vorace',
      'Vulnerabile'
   ],
   Z: ['Zelante', 'Zenitale', 'Zeppo', 'Zoticone', 'Zuccone', 'Zuzzurellone']
};

export default function AdjectivesPage() {
   const classes = useStyles();

   useEffect(() => {
      ReactGA.pageview(`/app/adjectives`);
   }, []);

   return (
      <Box>
         <Paper className={classes.paper}>
            <Typography variant="h5">Aggettivi per i totem</Typography>
            <Typography paragraph>
               Di seguito trovate una lista di aggettivi che si possono
               associare al totem
            </Typography>
            <List className={classes.root} subheader={<li />}>
               {Object.keys(adjectivesList).map(title => (
                  <li key={`section-${title}`} className={classes.listSection}>
                     <ul className={classes.ul}>
                        <ListSubheader>{title}</ListSubheader>
                        {adjectivesList[title].map((item, index) => (
                           <ListItem key={`item-${title}-${index}`}>
                              <ListItemText primary={item} />
                           </ListItem>
                        ))}
                     </ul>
                  </li>
               ))}
            </List>
         </Paper>
      </Box>
   );
}
